.login {
  &_wrapper {
    position: relative;
    padding-top: 80px;
    min-height: calc(100vh - 100px);
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }
  &_form {
    border-radius: 25px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    padding: 40px;
    & h2 {
      color: $primary-color;
      margin-bottom: 20px;
      text-align: center;
    }
    form {
      display: flex;
      flex-direction: column;
      & input {
        margin: 10px 0 20px;
        padding: 10px;
        border-radius: 5px;
        border: none;
        background-color: #f6f6f6;
        max-width: 100%;
        &:active, &:focus {
          outline: 2px solid $secondary-color;
        } 
      }
      & button {
        width: fit-content;
        background-color: $secondary-color;
        color: white;
        padding: 10px;
        border-radius: 10px;
        font-weight: 600;
        border: none;
        outline: none;
        transition: ease 0.3s;
        margin-top: 20px;
        cursor: pointer;
        &:hover {
          background-color: darken($secondary-color, 10%);
          scale: 1.1;
        }
      }
    }
  }
}