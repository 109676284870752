.cta-banner {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  background-color: $primary-color;
  @media screen and (max-width: 1200px) {
    height: 190px; 
  }
  &_content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    @media screen and (max-width: 1200px) {
      flex-direction: column;  
      justify-content: center;  
    }
    & h3 {
      font-size: 1.7rem;
      font-weight: 600;
      color: white;
      width: fit-content;
      margin-left: 7%;
      @media screen and (max-width: 1200px) {
        margin: 10px;
        text-align: center;
      }
    }
    & a {
      position: relative;
      right: 0;
      margin-right: 7%;
      @media screen and (max-width: 1200px) {
        margin: 10px 0; 
      }
    }
  }
}