.privacy-and-legal {
padding-top: 80px;
}

#privacy-policy, #legal-notices {
  padding: 20px;
  & h1, h2 {
    color: $primary-color;
    margin: 10px 0;
  }
}