*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE et Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari et Opera */
  }
}

html, body, #root {
  width: 100%;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}

ul, ol {
  list-style-type: none;
}

img {
  max-width: 100%;
  display: block;
}