.contact {
  position: relative;
  padding-top: 80px;
  min-height: calc(100vh - 100px);
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  @media screen and (max-width: 768px){
    height: inherit;
    margin-bottom: 420px;
  }
  & h1 {
    color: $primary-color;
    margin: 40px auto;
    text-align: center;
  }
  &_form {
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 47.5%;
    margin-bottom: 40px;
    @media screen and (max-width: 768px){
      width: 100%;
    }
    & input, & textarea {
      margin: 10px 0;
      padding: 10px;
      border-radius: 5px;
      border: none;
      background-color: #f6f6f6;
      max-width: 100%;
      &:active, &:focus {
        outline: 2px solid $secondary-color;
      } 
    }
    &_text-and-submit {
      position: absolute;
      border-radius: 25px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      padding: 20px;
      margin: -20px 0 0 -20px;
      width: 47.5%;
      height: 375.94px;
      right: 0;
      display: flex;
      flex-direction: column;
      & textarea {
        height: 100%;
        resize: none;
      }
      @media screen and (max-width: 768px){
        right: inherit;
        width: 100%;
        margin-top: 350px;
      }
    }
    & button {
      width: fit-content;
      background-color: $secondary-color;
      color: white;
      padding: 10px;
      border-radius: 10px;
      font-weight: 600;
      border: none;
      outline: none;
      transition: ease 0.3s;
      margin-top: 20px;
      cursor: pointer;
      &:hover {
        background-color: darken($secondary-color, 10%);
        scale: 1.1;
      }
    }
  }
}