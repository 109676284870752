.header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  margin: 0 auto;
  transition: ease 0.3s;
  background: rgba(255,255,255,0.8);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  @media screen and (max-width: 768px){
    flex-direction: column;
    height: 80px;
  }
  &_logo {
    height: 80px;
    margin-left: 10%;
    transition: ease 0.3s;
    @media screen and (max-width: 768px){
      margin: 10px 0 0;
      height: 60px;
    }
    & img {
      height: 100%;
    }
    &:hover {
      scale: 1.1;
    }
  }
  &_nav {
    margin-right: 10vw;
    height: 80px;
    width: 480px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    @media screen and (max-width: 1024px) and (orientation: landscape) {
      margin-right: 20px;
      margin-left: 10vw;
    }
    @media screen and (max-width: 768px){
      display: none;
      text-align: center;
      width: 100%;
      justify-content: space-evenly;
    }
    &_link {
      width: 100px;
      transition: ease 0.3s;
      &:hover {
        color: $secondary-color;
        scale: 1.1;
      }
      &:first-child {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: $secondary-color;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      }
    }
  }
  &_burger {
    display: none;
    @media screen and (max-width: 768px){
      display: block;
      position: absolute;
      right: 5%;
      top: 27px;
      z-index: 9999;
      & svg {
        color: $primary-color;
        transition: ease 0.3s;
        font-size: 2rem;
        cursor: pointer;
        &:hover {
          color: $secondary-color;
        }
      }
      &_menu {
        position: fixed;
        top: 0;
        right: 0;
        width: fit-content;
        height: fit-content;
        background-color: white;
        transform: translateX(110%);
        transition: transform 0.3s ease-in-out;
        box-shadow: 0px 0 5px rgba(0,0,0,0.5);
        border-radius: 0 0 0 20px;
        &.open {
          transform: translateX(0);
          & svg {
            margin: 10px;
          }
        }
        &_shadow {
          visibility: hidden;
          opacity: 0;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100vh;
          background-color: rgba(0,0,0,0.5);
          transition: all 0.3s ease-in-out;
          &.open {
            visibility: visible;
            opacity: 1;
          }
        }
        &_nav {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;
          &_link {
            transition: ease 0.3s;
            font-size: 1.6rem;
            margin: 20px;
            padding: 10px 0;
            &:hover {
              color: $secondary-color;
              scale: 1.1;
            }
            &:first-child {
              display: flex;
              width: 100%;
              align-items: center;
              justify-content: center;
              color: white;
              background-color: $secondary-color;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
              padding: 30px;
              margin: 0;
            }
          }
        
        }
      }
    }
  }
}

@keyframes fallIn {
  0% {
    opacity: 0;
    transform: translateY(-80px);
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fall-on-load {
  opacity: 0;
  animation: fallIn 0.8s ease-in forwards;
}