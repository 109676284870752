.last-actuality_card {
  position: relative;
  flex: 0 1 300px;
  margin: 2%;
  padding: 15px;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  transition: ease 0.3s;
  &:hover {
    scale: 1.1;
    @media screen and (max-width: 768px) {
      scale: 1;
    }
  }
  &_image {
    position: relative;
    top: -15px;
    left: -15px;
    width: calc(100% + 30px);
    height: 150px;
    object-fit: cover;
    border-radius: 20px 20px 0 0;
    padding: -10px;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px 20px 0 0;
    }
  }
  &_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 150px);
  }
  &_tag {
    position: absolute;
    left: 0;
    top: 110px;
    padding: 0 10px;
    color: white;
    background-color: $secondary-color;
    box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.2);
  }
  &_title {
    color: $primary-color;
    margin-bottom: 25px;
  }
  &_end {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 0;
  }
  &_date {
    color: $primary-color;
  }
}