.about_card {
  position: relative;
  flex: 0 0 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 360px;
  margin: 2%;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  transition: ease 0.3s;
  &:hover {
    scale: 1.1;
    @media screen and (max-width: 768px) {
      scale: 1;
    }
  }
  & h3 {
    display: block;
    position: relative;
    text-align: center;
    color: $primary-color;
  }
  & p {
    text-align: center;
  }
  & svg {
    display: block;
    height: 50px;
    margin: 10px auto;
    color: $secondary-color;
  }
}