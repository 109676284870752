.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $primary-color;
  height: 100px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  &_mentions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    & a {
      display: block;
      color: white;
      text-align: center;
      border-left: 2px solid white;
      border-right: 2px solid white;
      padding: 0 10px;
      font-size: 0.8rem;
      margin-top: 10px;
      transition: ease 0.3s;
      &:nth-last-child(2), &:nth-last-child(1) {
        border-left: none;
      }
      &:hover {
        color: $secondary-color;
      }
    }
  }
  & hr {
    width: 50%;
    background-color: white;
    margin: 10px 0;
  }
  &_rights {
    margin-bottom: 10px;
    & p {
      text-align: center;
      color: white;
      width: 100%;
      font-size: 0.8rem;
      & a {
        transition: ease 0.3s;
        color: $secondary-color;
        padding-left: 5px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}