.actuality_card {
  position: relative;
  display: flex;
  width: 80%;
  height: fit-content;
  min-height: 300px;
  max-height: 300px;
  margin: 30px;
  padding-bottom: 50px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;
  &:hover {
    scale: 1.1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    & .admin_controls {
      z-index: 99;
      opacity: 1;
    }
  }
  &.transitioning {
    flex-direction: column;
  }
  &.expanded {
    flex-direction: column;
    &:hover {
      scale: 1;
    }
  }
  &.most-recent {
    outline: 2px solid $secondary-color;
  }
  &_latest {
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    background-color: $secondary-color;
    padding: 5px;
    z-index: 2;
  }
  & .banner-image {
    width: 30%;
    height: 300px;
    border-radius: 20px 0 0 20px;
    object-fit: cover;
    object-position: center 25%;
    z-index: 2;
    transition: all 0.3s ease;
    animation: opacityInAndOut 0.6s ease-in-out 0s;
    &.expanded {
      width: 100%;
      border-radius: 20px 20px 0 0;
    }
    @media screen and (max-width: 768px){
      width: 0;
      max-width: 0;
      height: 150px;
      &.expanded {
        max-width: 100%;
      }
    }
  }
  &_info {
    display: flex;
    flex-direction: column;
    width: 70%;
    transition: all 0.3s ease;
    animation: opacityInAndOut 0.6s ease-in-out 0s;
    & h2 {
      padding: 0 20px;
      margin: 15px 0;
    }
    & h2, h3, h4 {
      color: $primary-color;
    }
    @media screen and (max-width: 768px){
      width: 100%;
      justify-content: inherit;
      height: 100%;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 70%; /* Ajustez cette valeur selon la zone que vous souhaitez couvrir */
      background: linear-gradient(to top, rgb(255, 255, 255) 15%, rgba(255, 255, 255, 0.1));
      z-index: 1;
    }
    &.expanded {
      width: 100%;
      margin-bottom: -10px;
      &:before {
        display: none;
      }
    }
    &_date {
      padding: 0 20px;
      margin: 15px 0;
    }
    &_image {
      width: calc(100% + 40px);
      height: 300px;
      margin: 20px 0;
      object-fit: cover;
      object-position: center 25%;
    }
    &_section {
      padding: 0 20px;
      margin: 15px 0;
      & h3, h4 {
        margin-bottom: 10px;
      }
    }
  }
  &_tag {
    position: absolute;
    left: 0;
    top: 260px;
    padding: 0 10px;
    color: white;
    background-color: $secondary-color;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    z-index: 3;
    &.expanded {
      top: 260px;
      left: auto;
      right: 0;
    }
    @media screen and (max-width: 768px){
      display: none;
      &.expanded {
        display: block;
        top: 120px;
      }
    }
  }
  & svg {
    position: absolute;
    right: calc(50% - 18px);
    bottom: 10px;  
    padding: 10px;
    color: white;
    background-color: $secondary-color;
    border-radius: 50%;
    transition: ease 0.3s;
    z-index: 2;
    &:hover {
      cursor: pointer;
      scale: 1.2;
    }
    & path {
      transition: all 0.6s ease;
    }
    &.expanded path {
      transform-origin: center;
      transform: rotateX(180deg);
    }
  }
  & .admin_controls {
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    border-radius: 20px;
    z-index: -99;
    opacity: 0;
    transition: all 0.3s ease;
    background-color: rgba(0, 0, 0, 0.2); /* Couleur de fond semi-transparente */
    backdrop-filter: blur(10px); /* Effet de flou */
    & svg {
      position: relative;
      height: 75px;
      width: 75px;
      padding: 40px;
      right: inherit;
      bottom: inherit;
    }
  }
}

@keyframes opacityInAndOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}