.actuality_feed {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 100px);
  height: calc(100% - 100px);
  & h1 {
    color: $primary-color;
    margin: 40px auto;
    text-align: center;
  }
  & a {
    text-decoration: none;
    color: $secondary-color;
    transition: all 0.3s ease;
    margin-bottom: 20px;
    &:hover {
      scale: 1.1;
    }
  }
} 