.congres {
  &-modal {
    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }
    &-content {
      position: relative;
      background: white;
      padding: 20px;
      border-radius: 20px;
      width: 50%;
      max-height: 90%;
      min-width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease, visibility 0.3s ease;
      & form {
        display: flex;
        flex-direction: column;
        width: 90%;
      }
      & svg {
        position: absolute;
        font-size: 1.5rem;
        right: 20px;
        transition: all 0.3s ease;
        &:hover {
          cursor: pointer;
          scale: 1.4;
          color: $secondary-color;
        }
      }
      & h1, & p {
        text-align: center;
      }
      & h1 {
        color: $primary-color;
      }
      & input, & select {
        margin: 10px 0;
        padding: 10px;
        border-radius: 5px;
        border: none;
        background-color: #f6f6f6;
        &:active, &:focus {
          outline: 2px solid $secondary-color;
        }
      }
      & button {
        width: fit-content;
        background-color: $secondary-color;
        color: white;
        padding: 10px;
        border-radius: 10px;
        font-weight: 600;
        font-size: 1rem;
        border: none;
        outline: none;
        transition: ease 0.3s;
        margin-top: 20px;
        cursor: pointer;
        &:hover {
          background-color: darken($secondary-color, 10%);
          scale: 1.1;
        }
      } 
    }
  }
}

.StripeElement {
  padding: 10px 0;
}