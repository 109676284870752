.formation_form {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  &_header {
    position: relative;
    display: flex;
    flex-direction: column;
    & svg {
      position: absolute;
      font-size: 1.5rem;
      right: 0;
      transition: all 0.3s ease;
      &:hover {
        cursor: pointer;
        scale: 1.4;
        color: $secondary-color;
      }
    }
    & h2 {
      text-align: center;
      margin: -7px 0 20px;
      color: $primary-color;
    }
  }
  & form {
    display: flex;
    flex-direction: column;
    width: 50%;
    max-height: 90%;
    min-width: 300px;
    background-color: white;
    border-radius: 25px;
    padding: 30px;
    overflow: auto;
    & input, & select {
      margin: 10px 0;
      padding: 10px;
      border-radius: 5px;
      border: none;
      background-color: #f6f6f6;
      &:active, &:focus {
        outline: 2px solid $secondary-color;
      }
    }
    & button {
      width: fit-content;
      background-color: $secondary-color;
      color: white;
      padding: 10px;
      border-radius: 10px;
      font-weight: 600;
      border: none;
      outline: none;
      transition: ease 0.3s;
      margin-top: 20px;
      cursor: pointer;
      &:hover {
        background-color: darken($secondary-color, 10%);
        scale: 1.1;
      }
    }
  }
}

.pac-container {
  z-index: 9999;
}