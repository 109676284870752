.banner {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  z-index: 1;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &_content {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 50%;
    left: 50px;
    bottom: 180px;
    padding: 20px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 2;
    @media screen and (max-width: 768px) {
      width: 80%;
      left: 5%;
    }
    @media screen and (max-width: 1024px) and (orientation: landscape) {
      padding: 10px;
      height: calc(100vh - 110px);
      width: 80%;
      left: 5%;
      bottom: 10px;
    }
    & h1 {
      color: $primary-color;;
    }
    & h2 {
      color: $primary-color;
      margin-bottom: 20px;
      @media screen and (max-width: 1024px) and (orientation: landscape) {
        margin-bottom: 10px;
      }
    }

    & p {
      margin-bottom: 20px;
      @media screen and (max-width: 1024px) and (orientation: landscape) {
        margin-bottom: 10px;
      }
    }
    & .date-highlight {
      font-weight: bold;
      color: $primary-color;
    }
    &_buttons {
      display: flex;
      gap: 20px;
    }
    &_button {
      width: fit-content;
      background-color: $secondary-color;
      color: white;
      padding: 10px;
      border-radius: 10px;
      font-weight: 600;
      border: none;
      outline: none;
      transition: ease 0.3s;
      cursor: pointer;
      &:hover {
        background-color: darken($secondary-color, 10%);
        scale: 1.1;
      }
    }
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-150%);
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-on-load {
  opacity: 0;
  animation: slideIn 0.8s ease-in forwards;
}