.congres {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-header {
    display: flex;
    flex-direction: column;
    margin: 40px auto 0px;
    text-align: center;
    width: 80%;
    &_title {
      color: $primary-color;
      margin-bottom: 20px;
    }
    &_date {
      margin-top: 20px;
    }
  }
  &-introduction {
    width: 80%;
    margin: 10px auto 0;
    text-align: center;
    &_title {
      color: $primary-color;
      margin-bottom: 20px;
    }
    &_text {
      font-size: 1.2rem;
    }
  }
  &-programme {
    width: 80%;
    margin: 10px auto 40px;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 768px) {
      width: 90%;
    }
    &_title {
      color: $primary-color;
      margin: 0 auto 20px;
    }
    &-jour {
      display: flex;
      border: 2px solid $secondary-color;
      padding: 10px;
      border-radius: 10px;
      margin: 20px 0;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
      &_date {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10%;
        text-align: center;
        border-right: 1px solid $secondary-color;
        padding-right: 10px;
        font-weight: 600;
        color: $primary-color;
        @media screen and (max-width: 768px) {
          width: 100%;
          border-right: none;
          border-bottom: 1px solid $secondary-color;
          padding-bottom: 10px;
          padding-right: 0;
        }
      }
      &_text {
        padding-left: 10px;
        width: 85%;
        @media screen and (max-width: 768px) {
          padding-left: 0;
          margin-top: 10px;
          width: 100%;
          text-align: center;
        }
        & h3 {
          color: $primary-color;
          margin-bottom: 10px;
        }
      }
    }
  }
  & button {
    width: fit-content;
    background-color: $secondary-color;
    color: white;
    padding: 10px;
    border-radius: 10px;
    font-size: 2rem;
    font-weight: 600;
    border: none;
    outline: none;
    transition: ease 0.3s;
    margin-bottom: 40px;
    cursor: pointer;
    &:hover {
      background-color: darken($secondary-color, 10%);
      scale: 1.1;
    }
  } 
}