.last-actuality {
  display: flex;
  flex-direction: column;
  align-items: center;
  & h2 {
    margin: 0 0 10px;
    color: $primary-color;
  }
  &_cards {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    justify-content: space-evenly;

  }
}