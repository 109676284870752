.partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  & hr {
    margin-top: 70px;
  }
  &_title {
    margin: 0 0 10px;
    color: $primary-color;
  }
  .partner {
    width: 100px;
    height: 100px;
    & img {
      margin: 0 50px;
      height: 100px;
    }
  }
  & .slick-slider {
    width: 100%;
    height: 100px;
    overflow: hidden;
    margin: 50px 0;
    &_content {
      display: flex;
    }
  }
  & .slick-slide {
    width: fit-content !important;
  }
}