.formation_about {
  width: 100%;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & h1 {
    margin: 40px auto 0px;
    color: $primary-color;
  }
  & h3 {
    color: $primary-color;
    text-align: center;
  }
  &_intro{
    text-align: center;
  }
  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 25px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    width: 50%;
    min-width: 300px;
    margin: 40px 0;
    padding: 30px;
    transition: ease 0.3s;
    & h2 {
      margin: 20px 0 10px;
    }
    & p {
      text-align: center;
    }
    & hr {
      margin: 30px auto;

    }
    & h4 {
      margin: 0 0 20px;
      color: $primary-color;
    }

    & ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      & li {
        display: flex;
        margin: auto;
        max-width: 500px;
        & svg {
          margin-right: 10px;
          margin-top: 5px;
          scale: 1.2;
          & path {
            fill: $primary-color;
          }
        }
      }
    }
  }
  & .button {
    margin-top: 40px;
  }
}