.modal-container {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
}

.modal {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2); /* Couleur de fond semi-transparente */
  backdrop-filter: blur(10px); /* Effet de flou */
  z-index: 100;
  display: flex;
  justify-content: center;
  overflow: scroll;
  &_article {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: fit-content;
    overflow-y: auto;
    padding: 20px;
    margin: 40px 0;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 20px;
    & h2 {
      color: $primary-color;
    }
    &_preview {
      position: relative;
      display: flex;
      width: 90%;
      height: fit-content;
      flex-direction: column;
      margin: 30px;
      padding-bottom: 4;
      border-radius: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      & .banner_uploader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 300px;
        & img {
          width: 100%;
          height: 100%;
          border-radius: 20px 20px 0 0;
          object-fit: cover;
          object-position: center 25%;
        }
        & input {
          display: none;
        }
        &_icon {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: absolute;
          cursor: pointer;
          transition: all 0.3s ease;
          border-radius: 20px 20px 0 0;
          & svg {
            font-size: 75px;
            color: $secondary-color;
            transition: all 0.3s ease;
          }
          &:hover {
            background-color: rgba(0, 0, 0, 0.2); /* Couleur de fond semi-transparente */
            backdrop-filter: blur(10px); /* Effet de flou */
            & svg {
              scale: 1.2;
            }
          }
        }
      }
      & .article_data {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 20px 0 20px;
        & #title {
          font-size: 2rem;
          font-weight: 600;
          color: $primary-color;
          background-color: #f6f6f6;
          border: none;
          border-radius: 5px;
          padding: 0 10px;
          margin: 0 -10px;
          margin-bottom: 15px;
          overflow: hidden;
          min-height: 40px;
          resize: none;
        }
        & #date {
          margin: 0 -10px;
          padding: 0 10px;
          width: fit-content;
          background-color: #f6f6f6;
          border: none;
          border-radius: 5px;
          cursor: text;
        }
        & #tag {
          position: absolute;
          top: 260px;
          right: 0;
          padding: 0 10px;
          background-color: #f6f6f6;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          border: none;
          text-align: right;
          width: 130px;
        }
      }
      & .content_editor {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 10px 10px 10px;
        &_block {
          margin: 15px 0;
          &_textarea, &_title {
            padding: 0 10px;
            width: 100%;
            resize: none;
            background-color: #f6f6f6;
            border: none;
            border-radius: 5px;
          }
          &_title {
            font-size: 1.15rem;
            font-weight: 500;
            color: $primary-color;
          }
          &_textarea {
            font-size: 1rem;
            font-weight: 400;
          }
          &_date {
            margin: 10px 0;
            & input {
              background-color: #f6f6f6;
              border: none;
              border-radius: 5px;
              padding: 0 10px;
            }
            & label {
              margin-left: 30px;
              font-style: italic;
              font-size: 1rem;
              color: gray;
            }
          }
        }
      }
    }
  }
}