.button {
  width: fit-content;
  background-color: $secondary-color;
  color: white;
  padding: 10px;
  border-radius: 10px;
  font-weight: 600;
  border: none;
  outline: none;
  transition: ease 0.3s;
  cursor: pointer;
  &:hover {
    background-color: darken($secondary-color, 10%);
    scale: 1.1;
  }
}

.separator {
  width: 80%;
  height: 2px;
  background-color: $secondary-color;
  margin: 40px auto 30px;
  border: none;
}