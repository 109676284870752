.members {
  display: flex;
  flex-direction: column;
  align-items: center;
  &_title {
    margin: 0 0 10px;
    color: $primary-color;
  }
  &_cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 80%;
    height: fit-content;
  }
  &_card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    margin: 80px 4%;
    transition: ease 0.3s;
    &:hover .members_card_image_filter {
      opacity: 1;
    }
    
    &:hover .members_card_content {
      transform: scale(1.1);
    }
    &_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      &_filter {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: ease 0.3s;
      }
    }
    &_content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      bottom: -100px;
      width: 250px;
      height: 90px;
      text-align: center;
      padding: 10px;
      border-radius: 10px;
      background-color: white;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      transition: ease 0.3s;
      &_name {
        color: $primary-color;
        font-weight: 600;
      }
      &_role {
        font-size: 0.9rem;       
      }
    }
  }
}