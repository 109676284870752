.about {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  & h2 {
    margin: 0 auto 10px;
    color: $primary-color;
  }
  &_cards {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: calc(360px * 3 + 4% * 6); /* Largeur de 3 cartes plus les marges */
    margin: auto; /* Centrer le conteneur horizontalement */
    height: fit-content;
    /* Règles pour les grands écrans */
    @media (min-width: 1200px) {
      width: calc(300px * 3 + 4% * 6); /* Largeur de 3 cartes plus les marges */
    }
    /* Règles pour les tablettes */
    @media (min-width: 768px) and (max-width: 1199px) {
      width: calc(300px * 2 + 4% * 4); /* Largeur de 2 cartes plus les marges */
    }
    /* Règles pour les petits écrans (téléphones) */
    @media (max-width: 767px) {
      width: 90%; /* Prend toute la largeur disponible */
    }
  }
}