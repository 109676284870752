html, input, textarea {
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 12px;    
  }
}

h1, h2, h3, h4, h5, h6, p, span, a, li, button, input, textarea, label, blockquote, 
cite, figcaption, time, address, article, aside, details, figcaption, figure, footer, 
header, main, mark, nav, section, summary {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.5;
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

h2 {
  font-size: 2rem;
  font-weight: 600;
}

h3 {
  font-size: 1.3rem;
  font-weight: 500;
}

h4 {
  font-size: 1.15rem;
  font-weight: 500;
}